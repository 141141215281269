.home {
  margin-top: 6rem;
  .header-img {
    height: 70vh;
    width: 100vw;
    border-radius: 0;
    animation: header-img 1s ease;
    position: relative;
    h1 {
      background-color: rgba(5, 125, 9, 0.7);
      width: 90%;
      margin: 0 auto;
      padding: 1rem;
      border-radius: 1rem;
      position: absolute;
      bottom: 5rem;
      left: 0;
      right: 0;
      color: $blanco;
      font-family: $fuente-titulo;
    }
    @include tablet {
      border-radius: 0% 0% 60% 0%;
      width: 60vw;
      h1 {
        display: none;
      }
    }
  }
}
.header {
  display: flex;
  align-items: center;
  background-color: $blanco;
  .header-title {
    display: none;
    margin: 0 auto;
    animation: title 1s ease;
    background-color: rgba(5, 125, 9, 0.7);
    border-radius: 1rem;
    padding: 2rem 5rem;
    h1 {
      font-size: 6rem;
      font-family: $fuente-titulo;
      color: $blanco;
      letter-spacing: 0.2rem;
      margin: 0 0 1rem 0;
    }
    @include tablet {
      display: block;
    }
  }
}
@keyframes title {
  10% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes header-img {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.home-description {
  margin: 10rem auto 5rem auto;
  @include tablet {
    margin: 10rem auto 5rem auto;
  }
  p {
    font-size: 2.3rem;
  }
}

.caracteristicas {
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;
  @include tablet {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .campo {
    img {
      height: 20rem;
    }
    p {
      font-weight: bold;
    }
  }
}

.middle-bg {
  height: 50vh;
}

.btn {
  background-color: $verde;
  font-size: 1.8rem;
  color: $blanco;
  padding: 1rem 4rem;
  border: none;
  border-radius: 0.7rem;
  &:hover {
    background-color: $verde-claro;
    cursor: pointer;
    transform: scale(1.05);
  }
}

.btn-contactanos {
  margin-bottom: 7rem;
}
