.clientes {
  margin: 7rem auto;
}
.clientes-track {
  overflow: hidden;
  width: 90%;
  position: relative;
  margin: auto;
  display: grid;
  place-items: center;
}
.clientes-logos {
  display: flex;
  width: calc(190px * 14);
  animation: scroll 20s linear infinite;
  img {
    height: 140px;
    width: 100%;
    @include tablet {
      height: 140px;
    }
  }
}
.cliente {
  width: 190px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-190px * 7));
  }
}

.clientes-track::before,
.clientes-track::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
  content: "";
}

.clientes-track::before {
  left: 0;
  top: 0;
}

.clientes-track::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
