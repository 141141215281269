@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@1,900&display=swap");
$fuente-principal: "Lato", sans-serif;
$fuente-titulo: "Kanit", sans-serif;

//Tamaños para mixins
$telefono: 480px;
$tablet: 768px;
$desktop: 1024px;

//Colores
$amarillo: rgba(254, 220, 0, 0.993);
$verde: rgb(5, 125, 9);
$verde-claro: rgba(5, 125, 9, 0.8);
$blanco: #ffffff;
$gris: #f3f2f2;

//Fuentes
$regular: 400;
$bold: 700;
