.produccion {
  margin-top: 6rem;
  .header-produccion {
    height: 50vh;
    position: relative;
  }
  h1 {
    background-color: rgba(5, 125, 9, 0.7);
    width: 90%;
    margin: 0 auto;
    padding: 1rem;
    height: 7rem;
    border-radius: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 20vh;
    color: $blanco;
    font-family: $fuente-titulo;
    letter-spacing: 0.3rem;
    @include tablet {
      width: 50rem;
    }
  }
  h2 {
    margin-top: 8rem;
    text-transform: uppercase;
  }
}

.produccion-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .balanza,
  .proceso {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    @include tablet {
      flex-direction: row;
      align-items: center;
    }
    p {
      font-weight: bold;
    }
  }
}

.balanza {
  img {
    width: 100%;
    border: 5px solid rgba(5, 125, 9, 0.7);
    border-radius: 5px;
  }
  text-align: center;
  @include tablet {
    img {
      width: 50%;
      margin-right: 15rem;
    }
  }
}
.descarga {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 5rem;
  align-items: center;
  img {
    width: 100%;
    border: 5px solid rgba(5, 125, 9, 0.7);
    border-radius: 5px;
  }
  @include tablet {
    flex-direction: row-reverse;
    img {
      width: 40%;
      margin-left: 15rem;
    }
  }
  p {
    font-weight: bold;
  }
}

.proceso {
  img {
    width: 100%;
    border: 5px solid rgba(5, 125, 9, 0.7);
    border-radius: 5px;
  }
  text-align: center;
  @include tablet {
    img {
      width: 40%;
      margin-right: 15rem;
    }
  }
}

.productos {
  display: flex;
  flex-direction: column;
  margin: 3rem 0 5rem 0;
  @include tablet {
    flex-direction: row;
  }
  .producto {
    width: 100%;
    img {
      height: 10rem;
    }
    p {
      width: 80%;
      margin: 1rem auto;
    }
  }
}
