.almacenamiento {
  margin-top: 6rem;
  z-index: 10;
  .header-almacenamiento {
    height: 50vh;
    position: relative;
  }
  h1 {
    background-color: rgba(5, 125, 9, 0.7);
    width: 95%;
    margin: 0 auto;
    padding: 1rem;
    height: 7rem;
    border-radius: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 20vh;
    color: $blanco;
    font-family: $fuente-titulo;
    letter-spacing: 0.3rem;
    font-size: 3rem;
    @include tablet {
      width: 50rem;
      font-size: 5rem;
    }
  }
  h2 {
    font-size: 2.8rem;
    margin-top: 8rem;
    text-transform: uppercase;
  }
}

.almacenamiento-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alm-aceite {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  @include tablet {
    flex-direction: row;
    align-items: center;
  }
  p {
    font-weight: bold;
  }
  img {
    width: 100%;
    border: 5px solid rgba(5, 125, 9, 0.7);
    border-radius: 5px;
  }
  text-align: center;
  @include tablet {
    img {
      width: 40%;
      margin-right: 15rem;
    }
  }
}
.alm-exp {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 5rem;
  align-items: center;
  img {
    width: 100%;
    border: 5px solid rgba(5, 125, 9, 0.7);
    border-radius: 5px;
  }
  @include tablet {
    flex-direction: row-reverse;
    img {
      width: 50%;
      margin-left: 15rem;
    }
  }
  p {
    font-weight: bold;
  }
}
