.contacto {
  margin-top: 6rem;
  .header-contacto {
    height: 50vh;
  }
  h1 {
    background-color: rgba(5, 125, 9, 0.7);
    width: 90%;
    margin: 0 auto;
    padding: 1rem;
    height: 7rem;
    border-radius: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 27vh;
    color: $blanco;
    font-family: $fuente-titulo;
    letter-spacing: 0.3rem;
    @include tablet {
      width: 50rem;
    }
  }
  h2 {
    text-transform: uppercase;
    margin: 0 auto 3rem 0;
  }
}

.contacto-content {
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include tablet {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
  iframe {
    width: 90%;
    height: 300px;
    border: 2px solid $verde;
    border-radius: 1rem;
    @include tablet {
      width: 100%;
      height: 450px;
      margin-right: 3rem;
    }
  }
}

.form {
  width: 100%;
  margin-bottom: 5rem;
  .campo {
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 6rem;
    @include tablet {
      flex-direction: row;
      height: auto;
    }
    input {
      height: 3rem;
      width: 100%;
      background-color: rgba(5, 125, 9, 0.5);
      border-radius: 5px;
      border: 1px solid $verde;
      flex: 80%;
      font-size: 1.5rem;
    }
    label {
      flex: 20%;
      font-size: 1.8rem;
      margin-bottom: 0.5rem;
      @include tablet {
        margin-bottom: 0;
      }
    }
    textarea {
      height: 10rem;
      width: 100%;
      background-color: rgba(5, 125, 9, 0.5);
      border-radius: 5px;
      border: 1px solid $verde;
      flex: 80%;
      font-size: 1.6rem;
    }
  }
  .campo-msg {
    height: 10rem;
  }
  .submit-btn {
    width: 100%;
    text-align: center;
    @include tablet {
      text-align: end;
    }
  }
}
