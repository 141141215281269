html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Lato", sans-serif;
  color: rgb(102, 102, 102);
}

.container {
  width: 90%;
  max-width: 120rem;
  margin: 0 auto;
}
.text-center {
  text-align: center;
}
a {
  text-decoration: none;
}

h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3.6rem;
}
h3 {
  font-size: 3rem;
}
h4 {
  font-size: 2.5rem;
}
p {
  font-size: 1.8rem;
}
.swal2-popup {
  font-size: 1.6rem !important;
}

.loading {
  height: 5rem;
  animation: loading 2s linear infinite;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
