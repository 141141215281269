.nav-bg {
  background-color: $verde;
  height: 6rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 998;
}
.nav-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @include desktop {
    width: 90%;
    margin: 0 auto;
    max-width: 120rem;
  }

  .btn {
    margin: 0;
    padding: 0.8rem 3rem;
  }
  p {
    color: $blanco;
    &:hover {
      font-size: 1.6rem;
    }
  }
  a {
    text-decoration: none;
  }
}
.right-menu {
  display: flex;
  justify-content: flex-end;
}
.nav-logo {
  position: absolute;
  left: 4%;
  height: 15rem;
  @include tablet {
    height: 18rem;
    left: 9%;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  background-color: rgba(5, 125, 9, 0.7);
  backdrop-filter: blur(5px);
  width: 0;
  transition: height 0s ease;
  margin-top: 0;
  height: 0;
  @include desktop {
    flex-direction: row;
    height: 2rem;
    background-color: $verde;
    width: 100%;
    margin-top: 2rem;
  }
  a {
    opacity: 0;
    transition: opacity 0s ease;
    font-size: 2rem;
    padding: 1rem auto;
    color: $blanco;
    transition: transform 0.3s ease;
    @include desktop {
      padding: 2rem 1rem;
      opacity: 1;
      &:last-of-type {
        margin-right: 0;
      }
    }
    &:hover {
      color: $amarillo;
    }
  }
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.menu-btn {
  display: block;
  @include desktop {
    display: none;
  }
  background-color: $verde;
  border: 0;
  cursor: pointer;
  padding: 0;
  width: 6rem;
  height: 6rem;
  span {
    border-radius: 5px;
    background-color: $blanco;
    display: block;
    width: 60%;
    margin: 0.5rem auto;
    height: 0.3rem;

    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
}

.view {
  display: flex;
  flex-direction: column;
  width: 20rem;
  transition: width 1s ease;
  height: calc(100vh - 5rem);
  a {
    opacity: 1;
    margin: 1rem 1rem;
    transition: opacity 1s ease 0.5s;
  }
  @include desktop {
    flex-direction: row;
  }
}

//Animation
.open {
  .top-line {
    animation-name: top-line-animation;
  }
  .middle-line {
    animation-name: middle-line-animation;
  }
  .bottom-line {
    animation-name: bottom-line-animation;
  }
}

.top-line {
  animation-name: top-line-animation-close;
}
.middle-line {
  animation-name: middle-line-animation-close;
}
.bottom-line {
  animation-name: bottom-line-animation-close;
}

@keyframes top-line-animation {
  50%,
  100% {
    margin: 0.01rem auto;
    transform: translateY(3px) rotate(-45deg);
  }
}
@keyframes bottom-line-animation {
  50%,
  100% {
    margin: 0.01rem auto;
    transform: translateY(-3px) rotate(45deg);
  }
}
@keyframes middle-line-animation {
  50%,
  100% {
    margin: 0 auto;
    transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes top-line-animation-close {
  0% {
    margin: 0.01rem auto;
    transform: translateY(3px) rotate(-45deg);
  }
  50%,
  100% {
    margin: 0.5rem auto;
    transform: translateY(0) rotate(0);
  }
}
@keyframes bottom-line-animation-close {
  0% {
    margin: 0.01rem auto;
    transform: translateY(-3px) rotate(45deg);
  }
  50%,
  100% {
    margin: 0.5rem auto;
    transform: translateY(0) rotate(0);
  }
}
@keyframes middle-line-animation-close {
  0% {
    margin: 0 auto;
    transform: translateX(50px);
    opacity: 0;
  }
  50%,
  100% {
    margin: 0.5 auto;
    transform: translateX(0px);
    opacity: 1;
  }
}

.hide {
  @include desktop {
    display: flex;
  }
}
.close {
  animation: close 1s ease;
}
@keyframes close {
  0% {
    width: 20rem;
    height: calc(100vh - 5rem);
  }
  100% {
    width: 0;
    height: calc(100vh - 5rem);
  }
}
